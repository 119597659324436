export const listAttorneysForAdmin = /* GraphQL */ `
  query ListAttorneysForAdmin(
    $filter: ModelAttorneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttorneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        acceptsTCAndPP
        attorneyRating
        cognitoId
        approved
        createdAt
        updatedAt
        licenseNumber
        currentProfessionalResponsibilityInvestigations
        acceptsTCAndPP
        emailWork
      }
      nextToken
    }
  }
`;