import React from 'react';
import './App.css';
import AttorneyList from './components/AttorneyList'
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify from "aws-amplify";
import AWSConfig from './aws-exports'
Amplify.configure(AWSConfig);


function App() {
  return (
    <div className="App">
      <div style={{margin: '0 auto', display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <AmplifyAuthenticator>
      <AmplifySignIn
            headerText="Ask to create an account"
            hideSignUp="true"
            slot="sign-in"
      />
   
        <AttorneyList></AttorneyList>
        <div style={{marginTop: 'auto'}}>
          <AmplifySignOut />
        </div>
        </AmplifyAuthenticator>
      </div>
    </div>
  );
}

export default App;
